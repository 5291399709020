<template>
<div class="security">
  <div
      class="security-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >{{title}}</h2>
  </div>
  <ProductComponent
      :categories="categoryData"
      v-if="title === 'Smart Home Security'"
  />
  <SubComponentProduct
      v-if="title !== 'Smart Home Security'"
      :categories="subProductData"
  />
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
import ProductComponent from "@/components/ProductComponent";
import SubComponentProduct from "@/components/SubComponentProduct";

export default {
  name: "Security",
  components: {SubComponentProduct, ProductComponent, Footer},
  data(){
    return{
      title: 'Smart Home Security',
      subProductData: [],
      categoryData: [
        {
          name: 'Smart Alarm Devices',
          subcategory: ['Control Panel/Gateway HUB', 'Base Station', 'Keypad', 'Remote Controller', 'Key FOB', 'Panic/SOS buttons', 'Strobe Siren', 'Smart Tag', 'Range Extender', 'RFID tag', 'RF Repeater']
        },
        {
          name: 'Smart Alarm Sensors',
          subcategory: ['Smart Alarm Sensors', 'Door/Window sensor', 'PIR Motion detector', 'Glass break sensor', 'Water leak detector', 'Smoke sensor', 'Gas Sensor', 'Co sensor', 'Temp & Humidity sensor']
        },
        {
          name: 'Smart Alarm System',
          subcategory: ['Smart Alarm Kits', 'DIY Kits', 'HUB/Gateway + Sensors/Cameras']
        },
        {
          name: 'Smart Cameras',
          subcategory: ['Smart Indoor IP Cameras', 'Outdoor IP Cameras', 'Smart Video Doorbells', 'Smart NVR Kits']
        },
        {
          name: 'Smart Locks',
          subcategory: ['Smart Door Locks', 'Smart Key Locks', 'Smart Box Locks', 'Smart Padlocks']
        },
      ],
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/security-bg.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)

  },
  computed: {

  },
  watch:{

  },
  methods: {

  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.security{
  .security-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
}
</style>
